html {
  height: 100%;
  width: 100%;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

body {
  margin: 0;
  font-family: Open Sans, sans-serif;
  font-weight: lighter;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
}