.fhr-block {
  border-radius: 0.5rem;
  box-shadow: 0px 3px 20px 0 rgba(18, 18, 18, 0.35);
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.fhr-block--white {
  background-color: #fff;
}
